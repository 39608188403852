/* File: src/components/ExpandingCircleLoader.css */

.expanding-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* White background */
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .hat-image {
    width: 100px; /* Adjust size as needed */
    height: auto;
    position: relative;
    z-index: 2; /* Ensures the hat image is above the circle */
  }
  
  .expanding-circle {
    position: absolute;
    width: 50px; /* Initial width */
    height: 50px; /* Initial height */
    border-radius: 50%;
    border: 5px solid #57CC02; /* Circle border with transparency */
    z-index: 1;
    animation: expand 2s ease-in-out infinite;
  }
  
  @keyframes expand {
    0% {
      width: 50px;
      height: 50px;
      opacity: 1;
    }
    50% {
      width: 100px;
      height: 100px;
      opacity: 0.7;
    }
    100% {
      width: 150px;
      height: 150px;
      opacity: 0;
    }
  }
  