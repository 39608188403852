@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    font-family:DM Sans, sans-serif ;
    font-display:swap;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 0.5s ease-out forwards;
}