/* Custom style for highlighted steps */
.highlight-step  {
    background-color: #E0F7FA; /* Background color for the highlighted step */
    border-radius: 8px;
  }
  
  .highlight-step .ant-steps-item-title,
  .highlight-step .ant-steps-item-description {
    color: #006064; /* Text color for the highlighted step */
  }
  
  /* Hide step numbers */
  .ant-steps-item-icon {
    display: none !important
  }
  
  /* Additional styling for the step content */
  .ant-steps-item-content {
    padding-left: 0; /* Align text since numbers are hidden */
  }
  