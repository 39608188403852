html,
body {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

.content-container {
  overflow-y: auto; /* Ensure vertical scrolling is enabled */
  -webkit-overflow-scrolling: touch; /* Adds smooth scrolling on iOS */
}

.subheader .fixed {
  position: sticky;
  top: 90px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.subheader.fixed.dropdown-active {
  z-index: 1;
}

.college_sub_header {
  display: flex;
  gap: 5px;
  padding: 5px;

  color: black;
  background-color: white;
  z-index: 1;
  justify-content: center;
}

.college_sub_header_option {
  cursor: pointer;
  padding: 8px 12px;
  position: relative;
  transition: all 0.3s ease;
  user-select: none;
  display: inline-block;
}

.college_sub_header_option span {
  position: relative;
  display: inline-block;
  padding: 2px 4px;
}

.college_sub_header_option::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.college_sub_header_option:hover,
.subheader.fixed .college_sub_header_option:hover {
  color: #3aca2e !important;
  cursor: pointer;
}

.college_sub_header_active,
.subheader.fixed .college_sub_header_active {
  color: #3aca2e !important;
  border-bottom: 3px solid #3aca2e;
}

.subheader .college_sub_header_option,
.subheader.fixed .college_sub_header_option {
  transition: color 0.3s ease;
}

.college_sub_header_option::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
}

.college_form {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 4px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
}

.college_form_element input {
  outline: none;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  box-shadow: 1px 2px 4px 0px #00000040;
}

.college_form_element input:focus {
  outline: none;
}

.college_form_element input::placeholder {
  color: #d4d4d4;
}

@media (max-width: 767px) {
  .mobile-mt-133 {
    margin-top: 133px;
  }
  .college_text {
    top: 213px;
    width: 440px;
    font-family: "DM Sans";
    font-size: 22px;
    font-weight: 700;
    margin-left: 10px;
  }
  .college_slider {
    width: 400px;
    height: 336px;
    top: 285px;
    margin: 0 auto;
  }
  .college_main {
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .tags-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 10px;
    margin-top: 40px;
  }

  .tags-item {
    width: auto;
    height: 45px;
    border-radius: 20px;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }

  .tags-item:nth-child(1),
  .tags-item:nth-child(3),
  .tags-item:nth-child(5) {
    margin-left: 10px;
    margin-right: auto;
  }

  .tags-item:nth-child(2),
  .tags-item:nth-child(4),
  .tags-item:nth-child(6) {
    margin-left: auto;
    margin-right: 10px;
  }

  .left-margin {
    margin-left: 30px;
    margin-right: auto;
  }

  .right-margin {
    margin-left: auto;
    margin-right: 30px;
  }
  .apply_buttons,
  .counselling_buttons {
    height: 45px;
    border-radius: 20px;
    padding: 10px;
    margin-top: 50px;
    font-size: 14px;
  }

  .apply_buttons {
    background-color: #3aca2e;
    color: white;
    width: 170px;
  }

  .counselling_buttons {
    background-color: transparent;
    color: black;
    border: 1px solid #3aca2e;
    width: 170px;
  }

  .buttons-container {
    display: flex;
    padding: 0 10px;
    height: 180px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media (max-width: 768px) {
  .center-content {
    margin-left: 30px;
    box-shadow: 1px 2px 11px 4px #00000040;

    width: 369px;
  }

  .subheader {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .subheader::-webkit-scrollbar {
    display: none;
  }

  .college_sub_header {
    display: inline-flex !important;
    flex-wrap: nowrap !important;
    padding: 10px 16px;
    gap: 20px;

    justify-content: flex-start !important;
    overflow-x: auto;
  }

  .college_sub_header > * {
    flex: 0 0 auto !important;
    white-space: nowrap !important;
  }

  .college_sub_header_option {
    display: inline-block !important;
    white-space: nowrap !important;
    padding: 8px 12px;
    flex-shrink: 0 !important;
    min-width: fit-content !important;
  }
}

.mobile-form-shadow {
  box-shadow: 1px 2px 11px 4px #00000040;
}

.college_main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.content-container {
  position: relative;
  z-index: 1;
}

.content-container::-webkit-scrollbar {
  width: 8px;
}

.content-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.content-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.content-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.college_sub_header {
  pointer-events: all !important;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Add these styles for both mobile and desktop */
.subheader.fixed {
  pointer-events: auto !important;
}

.subheader.fixed .college_sub_header_option {
  pointer-events: auto !important;
  cursor: pointer;
}

.college_sub_header_option {
  pointer-events: auto !important;
  cursor: pointer;
  transition: color 0.3s ease;
}

.college_sub_header_option:hover {
  color: #3aca2e;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.subheader::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.subheader {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar but keep functionality */
.subheader::-webkit-scrollbar,
.college_sub_header::-webkit-scrollbar {
  display: none;
}

.subheader,
.college_sub_header {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.subheader {
  position: relative;
  width: 100%;

  transition: all 0.3s ease;
}

.subheader.fixed {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.subheader.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Add a placeholder div to prevent content jump when subheader becomes fixed */
.subheader-placeholder {
  display: none;
}

.subheader-placeholder.active {
  display: block;
}

.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
