.menu:hover{
    background-color:#F0F0FE;
    color: #41B923 !important
  
}
.dashboard_sidebar_element {
    width: 100%;
    position: relative;
    z-index: 1; /* Set a base z-index for sidebar elements */
}

.submenu {
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 9999; /* Ensure it's on top of everything else */
    width: 200px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
    left: 70px; /* Adjust based on the sidebar width */
    top: 0; /* Align at the top of the parent element */
    overflow: visible; /* Ensure content is not cut off */
}

.dashboard_sidebar_element:hover .submenu,
.dashboard_sidebar_element .submenu:hover {
    display: block; /* Keep submenu visible when hovering over it */
}
  
  .submenu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 999;
  }
  
  .submenu ul li {
    padding: 5px 0;
  }
  
  .submenu ul li a {
    color: #333;
    text-decoration: none;
    font-size: 12px;
  }
  
  .submenu ul li a:hover {
    color: #41B923 !important
  }
  